import React from "react";
import { Button } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";

const WhatsAppButton = () => {
  const phoneNumber = "+60166362196"; // Wyngs contact
  const message = encodeURIComponent("Hello, I need assistance!"); // Prefilled message

  const openWhatsApp = () => {
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, "_blank");
  };

  return (
    <Button
      type="primary"
      shape="circle"
      icon={<WhatsAppOutlined />}
      size="large"
      onClick={openWhatsApp}
      style={{
        position: "fixed",
        bottom: "100px",
        right: "20px",
        width: "50px",
        height: "50px",
        zIndex: 1000,
        backgroundColor: "#25D366",
        borderColor: "#25D366",
        color: "white",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    />
  );
};

export default WhatsAppButton;
